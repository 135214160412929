

$(document).ready(function(){

    var $meta = $('#Button-Meta');
    var $curr = $('#Project-Count-Current');
    var $total = $('#Project-Count-Total');
    var $prev_project = $('#Prev-Project');
    var $next_project = $('#Next-Project');
    var $project_text = $('.project-text-holder');
    var total_count = $('.projects .project-image-holder').length/2-1;

    var curr_project = 0;
    var total_projects = $('.project-image-holder').last().data('project-index');
    var initLoad;
    var options = {
        cssEase: 'linear',
        speed: 500,
        useTransform: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 20000,
        initialSlide: currSlide,
        pauseOnHover: false,
        swipeToSlide: false,
        accessibility: false,
        draggable: false,
        touchMove: false,
        swipe: false,
        waitForAnimate: true,
        adaptiveHeight: true
    }

    var currSlide = 0;

    //return true;
    var $slick = $('.projects').slick(options);
    $('.projects').on('click', slickNav);
    $prev_project.on('click', prevProject);
    $next_project.on('click', nextProject);
    $meta.on('click', toggleMeta);

    setOptions();
    setActive();
    animateUp();
    document.addEventListener('lazyloaded', function(e){
        console.log( $('.slick-cloned')[1] )
        var $clone = $('.slick-cloned')[1];
        var bg = $('.slick-slide[data-slick-index="0"] .project-image').attr('style');
        $( $clone ).find('.project-image').attr('style', bg);

    });

    $(document).keydown(function(e) {
        switch(e.which) {
            case 37: // left
            $slick.slick('prev');
            break;
    
            case 38: // up
            $slick.slick('next');
            break;
    
            case 39: // right
            $slick.slick('next');
            break;
    
            case 40: // down
            $slick.slick('prev');
            break;
    
            default: return; // exit this handler for other keys
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)
    });
    

    function toggleMeta(e){
        e.stopPropagation();
        $('body').toggleClass('show-meta');
        if($('body').hasClass('show-meta')){
            $('#Project-Read-More').text('(Close)');
        } else {
            $('#Project-Read-More').text($('#Project-Read-More').data('text'));
        }
    }
    
    function animateUp() {
        $('body').addClass('show loading');
        setTimeout(function(){
            
        }, 1000);
        initLoad = setInterval(function(){
            console.log('loading')
            //slick initialized
            if($('.slick-initialized').length > 0) {

                //first slides active
                if($('.slick-active').length > 0) {

                    //first images loaded
                    if($('.slick-active .project-image.lazyloaded').length > 1) {
                        console.log('ready');
                        setTimeout(function(){
                            $('body').addClass('loaded').removeClass('loading');
                        }, 500);
                        
                    }
                }
            }
        }, 200);
        /*
        if($.cookie('up') == undefined ){
            $.cookie('up', 'true', { expires: 7 });
            $('body').addClass('show');
            setTimeout(function(){
                $('body').addClass('loaded');
            }, 1000);
        } else {
            $('body').addClass('read');
        }
        */
    }

    function slickNav(e){
        if(e.clientX > $(window).width()/2) {
            $slick.slick('next');
        } else {
            $slick.slick('prev');
        }
    }

    function setOptions(){
        if($(window).width() < 768) {
            options.speed = 250;
            $slick.slick("slickSetOption", 'speed', options.speed, true)
        } else {
            options.speed = 500;
            $slick.slick("slickSetOption", 'speed', options.speed, true)
        }
    }

    function setActive(){
        $('body').scrollTop(0);
        $meta.find('#Project-Meta').text($(".slick-active .project-image").data("title"));
        var index = $(".slick-active .project-image-holder").data('index-display');
        curr_project = $(".slick-active .project-image-holder").data('project-index');
        var total = $('.slick-slide:not(.slick-cloned) .project-image-holder[data-project-index="'+curr_project+'"] .project-image:not(.blank)');
        $curr.html(index);
        $total.html(total.length);
        $('.project-text').hide();
        console.log(curr_project);
        $project_text.find('.project-text').eq(curr_project).show();
        currSlide = $slick.slick('slickCurrentSlide');
        $('#Project-Num-Count-Current').html($('.slick-active').data('slick-index') + 1);
        $('#Project-Num-Count-Total').html(total_count + 1);
        $('body').scrollTop(0);
    }

    function nextProject(e){
        e.stopPropagation();
        curr_project++;
        curr_project = curr_project <= total_projects ? curr_project : 0;
        var next = $('[data-project-index="'+curr_project+'"]').closest('.slick-slide').data('slick-index');
        $slick.slick("slickSetOption", 'speed', 10, true)
        $slick.slick('slickGoTo', next);
        $slick.slick("slickSetOption", 'speed', options.speed, true);
    }

    function prevProject(e){
        e.stopPropagation();
        curr_project--;
        curr_project = curr_project >= 0 ? curr_project : total_projects;
        var prev = $('[data-project-index="'+curr_project+'"]').closest('.slick-slide').data('slick-index');
        $slick.slick("slickSetOption", 'speed', 10, true)
        $slick.slick('slickGoTo', prev);
        $slick.slick("slickSetOption", 'speed', options.speed, true);
    }

    $slick.on('afterChange', function(event, slick, currentSlide, nextSlide){
        //console.log(nextSlide);
        //console.log($(".slick-active .project-image").data("title"));
        $('.slick-slide').css('min-height', $('.project-left .slick-current').height())
        setActive();
      });

    $(window).on('mousemove', function(e){
        if(e.clientX < $(window).width()/2) {
            $('.left-arrow').show();
            $('.right-arrow').hide();
        } else {
            $('.right-arrow').show();
            $('.left-arrow').hide();
        }
    });

    $(window).on('resize', function(){
        setOptions();
    });

    $('#Button-About').on('click', function(e){
        e.stopPropagation();
        e.preventDefault();
        $('body').scrollTop(0);

        $('body').removeClass('show-index');
        $('body').toggleClass('show-about');
        $(this).toggleClass('active');
        $('#Project-Read-More').text($('#Project-Read-More').data('text'));
        $('#Button-Index').removeClass('active').text($('#Button-Index').data('text'));
        $('.projects-index').scrollLeft($('.projects-index').scrollLeft() + $('.project-index-holder').eq(currSlide).offset().left - 12);
        swapText($(this));
        toggleSlick();
        $('body').removeClass('show-meta');
        $('body, html').scrollTop(0);
        
    });

    $('#Button-Index').on('click', function(e){
        e.stopPropagation();
        e.preventDefault();
        $('body, html').scrollTop(0);
        
        $('body').removeClass('show-about');
        $('body').toggleClass('show-index');
        $('#Project-Read-More').text($('#Project-Read-More').data('text'));
        $('#Button-About').removeClass('active').text($('#Button-About').data('text'));
        $(this).toggleClass('active');
        
        $('.projects-index').scrollLeft($('.projects-index').scrollLeft() + $('.project-index-holder').eq(currSlide).offset().left - 12);
        swapText($(this));
        toggleSlick();
        $('body').removeClass('show-meta');
        $('body').attr('data-index-mode', 'thumbs');
        
    });
   
    $('#Button-Index-List').on('click', function(e){
        e.stopPropagation();
        e.preventDefault();
        $('body, html').scrollTop(0);

        $('body').attr('data-index-mode', 'list');
        $('.projects-index').scrollLeft($('.projects-index').scrollLeft() + $('.project-index-holder').eq(currSlide).offset().left - 12);
    });

    $('#Button-Index-Thumbs').on('click', function(e){
        e.stopPropagation();
        e.preventDefault();
        $('body, html').scrollTop(0);

        $('body').attr('data-index-mode', 'thumbs');
    });

    $('header a').on('click', function(e){
        e.preventDefault();
        if($('.projects').hasClass('slick-initialized')){
            $slick.slick("slickSetOption", 'speed', 0, true)
            $slick.slick('slickGoTo', 1);
            $slick.slick("slickSetOption", 'speed', options.speed, true);
         
        } else {
            initSlick();
        }
        
    });

    $('.index-link').on('click', function(){
        var inx = $(this).index();
        $('body, html').scrollTop(0);
        //console.log('currSlide ' + currSlide);
        
        //console.log(slick_index.closest('.slick-slide'));
        var slick_index = $('.project-image-holder[data-project-index="'+inx+'"]').first();
        initSlick();
        curr_project = inx;
        var next = $('[data-project-index="'+curr_project+'"]').closest('.slick-slide').data('slick-index');
        currSlide = next;
        $slick.slick("slickSetOption", 'speed', 0, true)
        $slick.slick('slickGoTo', next);
        $slick.slick("slickSetOption", 'speed', options.speed, true);
    });

    function swapText($button) {

        if($button.hasClass('active')){
            $button.text('(CLOSE)');
        } else {
            $button.text($button.data('text'));
        }
        
    }

    function initSlick(){
        $('body').removeClass('show-about show-index show-meta');
        $('body, html').scrollTop(0);
        $('#Button-About').removeClass('active').text($('#Button-About').data('text'));
        $('#Button-Index').removeClass('active').text($('#Button-Index').data('text'));
        $('#Project-Read-More').text($('#Project-Read-More').data('text'));
        if(!$('.projects').hasClass('slick-initialized')) {
            $slick = $('.projects').slick(options);
            setActive();
            setTimeout(function(){
                $('body').on('click', slickNav);
                
            }, 100);
        }
    }

    $('.project-index-holder').on('click', function(){
        //toggleSlick();
        var $this = $(this);
        if($('.projects').hasClass('slick-initialized')){
            $slick.slick("slickSetOption", 'speed', 0, true)
            $slick.slick('slickGoTo', $this.index());
            $slick.slick("slickSetOption", 'speed', options.speed, true);
         
        } else {
            options.initialSlide =  Number($(this).index());
            initSlick();
        }
        //setActive();
    });

    function toggleSlick(){
        if($('body').hasClass('show-index') || $('body').hasClass('show-about')){
            if($('.projects').hasClass('slick-initialized'))
                $slick.slick('unslick');
                $('body').off('click', slickNav);
                setTimeout(function(){
                    $('.projects').scrollLeft($('.project-image-holder[data-project-index="'+curr_project+'"]').first().position().left);
                }, 1);
                
                
                $('.projects img').off('click').on('click', function(e){
                    //options.initialSlide =  Number($(this).closest('.project-image-holder').data('project-index'));
                    options.initialSlide =  Number($(this).closest('.project-image-holder').index() + 1);
                    currSlide = options.initialSlide;
                    initSlick();
                    setActive();
                    
                    setTimeout(function(){
                    }, 10);
                    
                });
        } else {
            options.initialSlide = currSlide;
            $slick = $('.projects').slick(options);
        }
    }


    
});